import React from 'react';
import ProformaJiraIssueFormsDirect from '@atlassian/jira-proforma-issue-forms-direct/src/ui/index.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication';

const ProformaJiraIssueFormsDirectWrapper = () => (
	<AuthenticationCheck>
		<ProformaJiraIssueFormsDirect />
	</AuthenticationCheck>
);

export default ProformaJiraIssueFormsDirectWrapper;
