import type { ProjectType } from '@atlassian/jira-common-constants';
import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';

type SupportedProjectType =
	| typeof CORE_PROJECT
	| typeof SERVICE_DESK_PROJECT
	| typeof SOFTWARE_PROJECT;

export const toSupportedProjectType = (
	projectType: ProjectType,
): SupportedProjectType | undefined => {
	switch (projectType) {
		case CORE_PROJECT:
		case SERVICE_DESK_PROJECT:
		case SOFTWARE_PROJECT:
			return projectType;
		default:
			return undefined;
	}
};
