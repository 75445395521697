import React, { useState, useEffect } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { getUrls, ANALYTICS_CHANNEL } from '@atlassian/jira-proforma-utils';
import { toProformaEnvironment } from '@atlassian/jira-proforma-utils/src/common/utils/to-proforma-environment/index.tsx';
import { toSupportedProjectType } from '@atlassian/jira-proforma-utils/src/common/utils/to-supported-project-type/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { JsmPageInteractiveEvent } from '@atlassian/jira-servicedesk-common/src/utils/browser-metric/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import type { BackendSettings } from '@atlassian/proforma-common-core/jira-common-models';
import {
	type JiraIssueFormsDirectSettings,
	JiraIssueFormsDirectApp,
} from '@atlassian/proforma-jira-issue-forms/direct';
import { usePathParam, useQueryParam } from '@atlassian/react-resource-router';

type Props = {
	isEmbed?: boolean;
};

const ProformaJiraIssueForms = ({ isEmbed }: Props) => {
	const accountId = useAccountId();
	const cloudId = useCloudId();
	const environment = useEnvironment();
	const { data: projectContext } = useProjectContext();
	const { locale } = useIntl();
	const { isProjectAdmin, projectId, projectKey, projectType, simplified } = projectContext ?? {};
	const [templateFormId] = usePathParam('templateFormId');
	const [issueTypeId] = usePathParam('issueTypeId');
	const [requestTypeId] = useQueryParam('requestTypeId');
	const [settings, setSettings] = useState<BackendSettings<JiraIssueFormsDirectSettings>>();

	useEffect(() => {
		const proformaSupportedProjectType = projectType && toSupportedProjectType(projectType);
		if (
			projectId &&
			projectKey &&
			simplified !== undefined &&
			templateFormId &&
			issueTypeId &&
			proformaSupportedProjectType
		) {
			setSettings({
				analytics: { channel: ANALYTICS_CHANNEL },
				flags: {},
				urls: getUrls(environment, cloudId),
				environment: toProformaEnvironment(environment),
				context: {
					projectId,
					projectKey,
					projectType: proformaSupportedProjectType,
					isSimplifiedProject: simplified,
					templateFormId: Number(templateFormId),
					issueTypeId,
					requestTypeId,
					userIsProjectAdmin: isProjectAdmin,
					intlLocale: locale,
					isEmbed: !!isEmbed,
				},
			});
		}
	}, [
		environment,
		cloudId,
		projectId,
		projectKey,
		projectType,
		simplified,
		templateFormId,
		issueTypeId,
		requestTypeId,
		isProjectAdmin,
		locale,
		isEmbed,
	]);

	if (projectContext !== null && projectId && settings) {
		return (
			<>
				<JiraIssueFormsDirectApp settings={settings} reporterAccountId={accountId ?? undefined} />
				<JsmPageInteractiveEvent />
			</>
		);
	}
	return null;
};

export default ProformaJiraIssueForms;
